const _projects = {
  search: '',//'searchable list of pages',
  // terrain: ['terrain generation', 'procedurally generated landscape'],
  // terrain: 'simple procedurally generated landscape',
  terrain: '3D landscape generation',
  // nonogram: ['nonogram solver', 'solves <a href="https://en.wikipedia.org/wiki/Nonogram">nonograms</a>'],
  nonogram: 'solves <a href="https://en.wikipedia.org/wiki/Nonogram">nonograms</a>',
  snakes: 'one- or two-player co-op snake',
  wordbase: 'clone of discontinued word game #highlight',
  snackman: "(it's Pac-Man)",
  // befruited: 'bejeweled as fruit',
  graffiti: ['graffiti wall', 'open to all (be nice)'],
  'turt-smurts': 'wise tortoise',
  // 'turt-smurts-2D': 'wise tortoise in two dimensions',
  // insult: "sometimes it's funny",
  floating: 'morphed Delaunay triangulation',
  // models: 'simple 3D things created in Blender',
  // domains: 'list of domains for this site',
  u: ['user profile', ''],
  notify: 'manage page notifications',
  reset: 'update password',
  home: 'landing page',
  // about: 'bio and contact',
  about: 'bio',
  // projects: 'highlighted project list',
  ink: '',
  // ly: 'link shortener & aggregator',
  // cloud: `phasing color cube`, // reminiscent of '<a href="https://www.youtube.com/watch?v=10Jg_25ytU0">Lusine - Just A Cloud</a>'
  // live: 'live chat',
  chat: 'message friends',
  // records: 'personal & global game scoreboards',
  // tally: 'habit tracker',
  tap: 'habit tracker',
  // slime: 'automata behavior visualization',
  'slime-ants': 'mass simulated behavior',
  // coffee: '\'buy me a coffee\'',
  coffee: 'support the site!',
  // donate: '',
  // minesweeper: '~minesweeper~',
  // arm: 'a sticky hand follows your cursor (<a href="https://en.wikipedia.org/wiki/Inverse_kinematics#Heuristic_methods">FABRIK</a>)',
  // gradients: 'generate gradients',
  // pixelworld: 'pixelated 2D world generation',
  pixelworld: 'pixelated 2D landscape generation',
  // bubble: 'a colorful grid avoids your cursor',
  tube: 'pulsating color circle',
  'dots-and-boxes': 'classic paper & crayon game',
  // wordle: [
  //     'wordle solver',
  //     'solves <a href="https://www.powerlanguage.co.uk/wordle/">Wordles</a>, <a href="wordle/#/leaderboard">compare solvers</a>'],
  wordle: 'solves <a href="https://www.powerlanguage.co.uk/wordle/">Wordles</a>, <a href="wordle/#/leaderboard">compare solvers</a>',
  ':<': ':>',
  wall: 'can hold up to 24x24 messages',
  pixels: 'pixel art graffiti wall',
  // txt: 'for grocery lists & ASCII art',
  paths: 'path planning demo',
  guestbook: `leave a message on a receipt`,
  // mountain: 'from the top of a mountain',
  bloom: 'bloom filter demo',
  // aoc: 'interactive Advent of Code solutions (incomplete)'
  egg: 'egg',
  settings: 'manage account settings',
  // 'follow-sync': 'sync Twitter & Mastodon follows',
  cookies: 'view stored info',
  // 'mastodon-dm': 'link to a Mastodon DM',
  // 'mouse-text': 'keyboard-less typing tool',
  // 'pico-repo': 'discover & share Pico apps',
  'dinder': 'make a recipe with someone',
  'switches': `flip 'em`,
  // 'morse': 'Morse Code interpreter',
  'websocket': 'WebSocket text frame translator',
  // 'crowdmeal': 'cheap meals made daily',
  'cards': '',
  'keys': 'api keys',
  'fishbowl': 'a party game (made for your smartwatch)',
  'wrap': 'text wrapper',
  'wwl-builder': 'starters for <a href="/lib/2/wwl/script.js">wwl.js</a>',
  'timer': 'timer',
  // 'domains': '',
  // 'spot': ['smolify', 'simple Spotify UI'],
  'matchbox': 'car-racing joycon couch game (local multiplayer)',
  'wwl-gallery': '<a href="/lib/2/wwl/script.js">wwl.js</a> gallery',
  // 'bugs':'',
  // 'uh':'',
  // 'ly':'',
  // 'manual-follows':' ̶b̶u̶g̶s̶ ̶',
  'html-resume': 'not a pdf (yet)',
  'tweet-embed': 'sandboxed tweet embeds',
  // 'otp': 'log in using an OTP',
  // 'audio_form': 'audio-based social media',
  // 'greeter': 'keep track of where you met',
  'greeter': 'a social diary #highlight',
  'tappy-square': 'basic flappy bird',
  'daily-nonogram': 'daily <a href="https://en.wikipedia.org/wiki/Nonogram">nonogram</a> puzzles',
  'gradients': 'color gradient generator',
  'color': 'add colored icons to your home screen',
  'kmeans': 'k-means clustering demo',
  'stream': 'my own social media. it\'s kinda like html VSCO',
  'lettercomb': 'remake of Capitals #highlight',
  'quadbase': '4-player word strategy game (WIP)',
  // 'multipals': 'word game like capitals for > 2 players (WIP)',
  'starter': 'suggestions for getting started on freshman.dev',
  'letterpress': 'best word game #highlight',
  'link-timer': 'open that thing u gotta work on, later!',
  // 'proses': '[WIP] a place for poetry and, possibly, even romance',
  'optimal-maps': 'countries from a better angle (WIP)',
  // 'donoboard': 'leaderboard for 8334 $1 slots',
  'donoboard': 'donation leadboard',
  // 'dinoboard': 'best dinosaurs',
  // 'dodoboard': 'various dodos',
  // 'linktree': 'profile overviews - icon, bio, links',
  'plat': 'US license plate chat',
  // 'not-linkedin': 'not LinkedIn',
  // 'splink': 'smaller spotify link preview',
  // 'developer-program': 'build web apps on freshman.dev',
  // 'daffodil': 'i split a bunch of the new LEGO daffodil sets',
  // 'capitals': 'i remade Capitals as /lettercomb',
  'itly': 'smaller iMessage link previews',
  // 'euphoria': 'track days you felt true euphoria',
  'rent-splitter': 'assign rooms & rent fairly',
  // 'twitter': 'twitter blue Threads icon',
  'apple-orange-banana': 'a stupid game',
  // 'radio': 'spotify playlist for freshman.dev',
  'recurder': 'periodic reminders',
  'collector': 'lists of links',
  'contact': 'contact me! also site discord, bug/feature submit, email',
  'selfchat': 'chat with yourself (productivity hack)',
  'cowork': 'chat & lofi',
  'chess': 'simple roomed chess',
  'light': 'light twitter (text-only)',
  'running': 'get faster',
  'textage': 'generate text responses',
  'emoji-banner': 'generate an emoji banner!',
  // 'westworld': 'coming soon (not actually tho, just a cool idea)',
  'you': 'draw on your camera',
  'list-picker': 'pick a random item from a menu',
  'poll': 'create a textable poll',
  'beam': '1hr download link',
  'square': 'put stuff in a square',
  'vibe': '24hr photos from bars/cafes/etc',
  // 'bracket': 'create a tournament bracket',
  'matchbox.html': 'car-racing couch game (local multiplayer)',
  'circuit.html': 'compilation of couch games (local multiplayer)',
  'rephrase': 'x7 variations of your phrase',
  'llm': 'talk to a personal LLM',
  'sd': 'image generation toy',
  'submerse': 'explore the ocean and caves',
  'web-app-store': 'a compilation of web apps',
  'petals': 'new word game',
}
// ''.split(' ').forEach(p => {
//     if (!_projects[p]) _projects[p] = '' });
export const alpha = 'qwertyuiopasdfghjklzxcvbnm'
export const searchProjects = Object.keys(_projects)
  .sort()
  .sort((a, b) => (alpha.includes(a[0]) ? 0 : 1) - (alpha.includes(b[0]) ? 0 : 1));
searchProjects.forEach(key => {
  if (typeof _projects[key] === 'string') {
      _projects[key] = ['', _projects[key]]
  }
})
export const projects = _projects;
export const project_years = {
  // wordbase: `'21`,
  // nonogram: `'18`,
  // wordle: `'22`,
  // 'pico-repo': `'23`,
  // 'dinder': `'23`,
  // 'crowdmeal': `'23`,
  // paths: `'22`,
  // guestbook: `'22`,
  // bloom: `'22`,
  // 'follow-sync': `'22`,
}

export const tags = {
  all: '',
  // game: 'befruited snackman snakes wordbase minesweeper dots-and-boxes',
  game: 'snackman snakes wordbase dots-and-boxes fishbowl matchbox tappy-square daily-nonogram lettercomb quadbase multipals letterpress apple-orange-banana chess cards matchbox.html submerse petals',
  // visual: 'cloud floating models terrain graffiti slime speckle arm color pixelworld bubble tube garden egg',
  visual: 'cloud floating models terrain graffiti slime-ants speckle arm gradients pixelworld bubble tube pixels egg gradients color optimal-maps',
  // social: 'chat graffiti live records speckle turt-smurts turt-smurts-2D u wordbase dots-and-boxes garden wall dinder',
  social: 'chat graffiti live records speckle turt-smurts u pixels wall dinder audio_form greeter proses plat not-linkedin cowork light vibe',
  site: 'notify reset search settings cookies otp donoboard developer-program',
  tool: 'tap txt follow-sync mastodon-dm switches morse websocket mouse-text keys wrap wwl-builder timer spot uh bugs ly manual-follows html-resume gradients link-timer optimal-maps plat itly rent-splitter selfchat running emoji-banner poll beam square list-picker bracket',
  demo: 'paths bloom kmeans',
  me: 'about coffee domains home projects guestbook',
}
export const projectTags = {}
Object.keys(tags).forEach(key => {
  tags[key] = new Set(tags[key].split(' '))
  tags[key].forEach(project => {
      projectTags[project] = (projectTags[project] ?? []).concat(key)
  })
})
